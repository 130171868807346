
import {BehaviorSubject} from "rxjs";

// A helper function which ensures all the values are present on the auth token
const validateAuthToken = authToken => authToken && authToken.tokenType && authToken.idToken;

// A helper function which maps a authToken to an Authorization header.
export const generateHeaders = (authToken) => {
  if (validateAuthToken(authToken)) {
    const {tokenType, idToken} = authToken;
    return {'Authorization': `${tokenType} ${idToken}`};
  } else {
    console.error("Cannot create auth token because it is null or undefined");
  }
};

// This is a helper function which returns a on progress handler to report the percentage completed of the upload
export const uploadProgressHandler = () => {
  const uploadProgress$ = new BehaviorSubject({percentCompleted: 0});
  const onUploadProgress = progressEvent => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    uploadProgress$.next({percentCompleted});
    if (percentCompleted === 100) {
      uploadProgress$.complete();
    }
  };

  return [onUploadProgress, uploadProgress$]
};
