import React, {useEffect, useRef, useState} from 'react'
import {interval} from "rxjs"
import Header from "./header/Header"
import {useMeasure} from "./hooks/useMeasure"
import {useAppContext} from "./AppContext"
import {useDataLoader} from "./hooks/useDataLoader"
import {LineChart} from "./components/lineChart/LineChart"
import DaySelector from "./components/daySelector/DaySelector"
import Live from "./live/Live"
import * as firebase from "firebase"
import {LiveChart} from "./components/liveChart/LiveChart"
import {theme} from "./components/Theme"
import FlexBox from "./components/layout/FlexBox"
import useOnMount from "./hooks/useOnMount"
import moment from "moment"
import Loading from "./components/atoms/loading/Loading"
import Notifications from "./notifications/Notifications"
import BarChartContainer from "./components/barChart/BarChartContainer";


function App() {
  const ctx = useAppContext();
  const {setSize, user} = ctx;
  const appRef = useRef(undefined)
  const size = useMeasure(appRef)
  useEffect(() => {
    setSize(size)
  }, [size])

  return (
    <div style={Style.container} ref={appRef}>
      <Header/>
      {user ? <SecureApp/> : <PublicApp/>}
    </div>
  );
}

const SecureApp = () => {
  const {loadingGrids, grids, loadGrids, user, setGridId} = useAppContext()
  useOnMount(() => {
    loadGrids(user.authToken)
  })

  // when the user only has one grid available to them automatically select it for them
  useEffect(() => {
    if (grids && grids.length === 1) {
      setGridId(grids[0])
    }
  }, [grids])

  return loadingGrids
    ? <Loading fullScreen message={"Getting setup one moment..."}/>
    : <AppRouter/>
}

const AppRouter = () => {
  const {showNotifications, gridId, grids} = useAppContext()
  if (showNotifications) {
    return <Notifications/>
  } else if (gridId) {
    return <GridDashboard/>
  } else if (!grids || grids.length === 0) {
    return <LinkToGridRequest/>
  } else if (!gridId) {
    return <GridSelector/>
  }
  return <div>Doh! who put the lights out?</div>
}

const GridSelector = () => {
  const {grids, setGridId} = useAppContext()

  return (
    <FlexBox centered column>
      <FlexBox style={Style.gridSelectorTitle} vMargin>Please select a grid</FlexBox>
      <FlexBox centered column style={{width: 300}}>
        {grids.map(grid => <FlexBox centered
                                    style={Style.gridSelectorItem}
                                    key={grid}
                                    onClick={() => setGridId(grid)}>{grid}</FlexBox>)}
      </FlexBox>
    </FlexBox>
  )
}

const LinkToGridRequest = () => {
  const {user, sendLinkToGridRequest, loadGrids} = useAppContext()
  useOnMount(() => {
    sendLinkToGridRequest(user.authToken)
  })
  useOnMount(() => {
    const subscription = interval(5000).subscribe(() => {
      loadGrids(user.authToken)
    })
    return () => { subscription.unsubscribe() }
  })
  return (
    <FlexBox style={{color: theme.fontColor4}} centered column>
      <FlexBox style={{fontSize: 24, marginTop: 40, marginBottom: 30}}>Welcome to Microgrid Energy!</FlexBox>
      <FlexBox>Hi {user.displayName}, there are currently no grids linked to your account.</FlexBox>
      <FlexBox>We have fired off a notification to the team.</FlexBox>
      <FlexBox vMargin>You can expect to hear from us shortly!</FlexBox>
    </FlexBox>
  )
}

const GridDashboard = () => {
  const {loading, loadData, selectedDay} = useAppContext()

  useEffect(() => {
    loadData()
  }, [selectedDay])

  return (
    <FlexBox column>
      <Live/>
      <LiveChartContainer/>
      {!loading && <MonthToDateChartContainer/>}
      {!loading && <YearToDateContainer/>}
      {/*<DataView/>*/}
    </FlexBox>
  )
}

const PublicApp = () =>
  <FlexBox column centered style={{height: "80vh", color: theme.fontColor1}}>
    <h1>Please sign in</h1>
  </FlexBox>

const DataView = props => {
  const ctx = useAppContext();

  const data = useDataLoader("04-18-2019");
  return <LineChart data={data}/>
}

const useLoadLiveData = (gridId, day) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  useEffect(() => {
    const start = moment(day).startOf("day")
    const end = moment(day).endOf("day")
    const db = firebase.firestore()
    const unsub = db.collection(`data/${gridId}/nano-live`)
      .where("date", ">=", start.valueOf())
      .where("date", "<=", end.valueOf())
      .onSnapshot(querySnapshot => {
        setData(querySnapshot.docs.map(doc => {
          const data = doc.data();
          if (!data.date) data.date = data.dateCreated
          return data
        }))
        setLoading(false)
      });
    return () => {
      unsub()
    }
  }, [gridId, day, moment])
  return [loading, data]
}

const Style = {
  gridSelectorTitle: {
    fontSize: 24
  },
  gridSelectorItem: {
    minWidth: "100%",
    fontSize: 18,
    backgroundColor: theme.backgroundColor2,
    cursor: "pointer"
  },
  container: {
    color: theme.fontColor1,
  },
  sectionStyle: {
    marginLeft: 20,
    marginRight: 20,
    paddingBottom: 10,
    borderBottom: `solid 1px ${theme.fontColor1}`
  },
  titleStyle: {
    color: theme.fontColor1,
  },
  noData: {
    minHeight: 100,
    borderRadius: 5,
    backgroundColor: theme.backgroundColor6
  }
}

const LiveChartContainer = () => {
  const {gridId, selectedDay} = useAppContext()
  const [loading, data] = useLoadLiveData(gridId, selectedDay)

  if (loading) return <Loading fullScreen/>

  if (!data.length) {
    return <FlexBox centered margin style={Style.noData}>
      There is no data for the {moment(selectedDay).format("dddd DD MMM YYYY")}
    </FlexBox>
  }

  return (
    <FlexBox column style={Style.container}>
      <FlexBox item spaceBetween style={Style.sectionStyle}>
        <FlexBox>{moment(selectedDay).format("dddd DD MMM YYYY")}</FlexBox>
        <FlexBox><DaySelector/></FlexBox>

      </FlexBox>
      <LiveChart data={data}/>
    </FlexBox>
  )
}

const MonthToDateChartContainer = () => {
  const {dataGroupByDay: data, selectedDay} = useAppContext()
  if (!data || !data.length) return null

  const mapXAxisToLabel = date => {
    return moment(date).date()
  }

  return (
    <FlexBox column style={Style.container}>
      <FlexBox item spaceBetween style={Style.sectionStyle}>
        <FlexBox>Month to date ({moment(selectedDay).format("MMMM")})</FlexBox>
      </FlexBox>
      {data ? <BarChartContainer {...{data, mapXAxisToLabel}}/> : null}
    </FlexBox>
  )
}

const YearToDateContainer = () => {
  const {dataGroupByMonth: data, selectedDay} = useAppContext()
  if (!data || !data.length) return null

  const mapXAxisToLabel = date => {
    return moment(date).format("MMM")
  }

  return (
    <FlexBox column style={Style.container}>
      <FlexBox item spaceBetween style={Style.sectionStyle}>
        <FlexBox>Year to date ({moment(selectedDay).format("YYYY")})</FlexBox>
      </FlexBox>
      {data ? <BarChartContainer {...{data, mapXAxisToLabel}}/> : null}
    </FlexBox>
  )
}

export default App;
