import React from "react";
import "./HSpinner.css"
import {theme} from "../../Theme";

const HSpinnerStyle = {
  dots: {
    backgroundColor: theme.linkColor
  }
};

export default ({style, dotStyle}) => {
  const mergedDotStyle = {...HSpinnerStyle.dots, ...dotStyle};
  return (
    <div className="lds-ellipsis" style={style}>
      <div style={mergedDotStyle}/>
      <div style={mergedDotStyle}/>
      <div style={mergedDotStyle}/>
      <div style={mergedDotStyle}/>
    </div>
  )
};
