import React, {useRef, useEffect} from "react";
import * as d3 from "d3";
import "./LiveChart.css";

const margin = {top: 20, right: 30, bottom: 50, left: 50};
const chartStyle = {
  width: 'calc(100vw-0)',
  height: 250
};

const drawBackground = (selection, widthLessMargin, heightLessMargin) => {
  selection
    .append("rect")
    .style("fill", "rgba(0, 0, 0, 0.2)")
    .attr("width", widthLessMargin)
    .attr("height", heightLessMargin)
};

const drawLines = (selection, data, widthLessMargin, heightLessMargin, xScale, yScale, yScaleRight) => {

  const line = d3.line()
    .curve(d3.curveBasis)
    .x(function (d) {
      return xScale(d.date);
    })
    .y(function (d) {
      return yScale(d.value);
    });

  const lineRight = d3.line()
    .curve(d3.curveBasis)
    .x(function (d) {
      return xScale(d.date);
    })
    .y(function (d) {
      return yScaleRight(d.value);
    });

  const voltage = data.map(({date, voltage}) => ({date, value: voltage}));
  const meter = data.map(({date, total}) => ({date, value: total}));
  const pv = data.map(({date, channel1}) => ({date, value: channel1}));
  const _data = [
    {id: "voltage", values: voltage},
    {id: "meter", values: meter},
    {id: "pv", values: pv}];
  selection.select('.lines').remove();

  selection
    .append('g')
    .selectAll('path')
    .data(_data)
    .enter()
    .append("path")
    .attr("class", "line")
    .attr("d", d => {
      if (d.id === 'voltage') {
        // return lineRight(d.values)
      } else {
        return line(d.values)
      }
    })
    .style("stroke", d => {
      switch (d.id) {
        case ("voltage"):
          return "#00b71f";

        case ("pv"):
          return "#1193cd";

        case ("meter"):
          return "#cd7c0a";

        default:
          return "#fff";
      }
    })
    .style("stoke-width", "1.5px")
    .style("fill", "none");
};

const drawXAxis = (selection, width, heightLessMargin, xScale) => {
  selection.select('.x-axis').remove();
  selection.select('.xx-axis').remove();

  const hourOfDay = d3.axisBottom(xScale)
    .tickPadding(5)
    .ticks(d3.timeMinute.every(60))
    .tickFormat((d, i, c) => { // 00...09, 10, 11, 12, 13...23
      // return i !== c.length ? d3.timeFormat('%H:%M')(d) : null;
      return i !== c.length - 1 ? d3.timeFormat('%-H')(d) : null;
    });

  const dayMonthYear = d3.axisBottom(xScale)
    .tickPadding(5)
    .ticks(d3.timeMinute.every(60))
    .tickSize(0)
    .tickFormat((d, i, c) => {
      // only draw the day month year label on the start of the day
      if (i === 0) {
        return d3.timeFormat("%d %A %B %Y")(d);
      } else {
        return null;
      }
    });

  selection
    .append("g")
    .attr("class", "x-axis")
    .attr("transform", "translate(0," + heightLessMargin + ")")
    .call(hourOfDay)
    .selectAll("text")
    .style("text-anchor", 'start')
    .style("fill", '#c1c1c1')
    .style("stroke", 'none');

  selection
    .append("g")
    .attr("class", "xx-axis")
    .attr("transform", "translate(0," + (heightLessMargin + 20) + ")")
    .call(dayMonthYear)
    .selectAll("text")
    .style("text-anchor", 'start')
    .style("fill", '#c1c1c1')
    .style("stroke", 'none');
};

const drawYAxis = (selection, widthLessMargin, yScale, yScaleRight, maxChannel1, maxTotal) => {
  selection.select('.y-left-axis').remove();
  selection.select('.y-right-axis').remove();

  const layer = selection.append("g")
    .attr("class", "y-left-axis")
    .call(d3.axisLeft(yScale));

  // draw the right hand axis
  // selection.append("g")
  //   .attr("class", "y-right-axis")
  //   .attr("transform", `translate(${widthLessMargin},0)`)
  //   .call(d3.axisRight(yScaleRight).ticks(3));

  // Add the keys at the top of the chart
  // layer.append("text")
  //   .attr("transform", `translate(${widthLessMargin - 5},5)`)
  //   .attr("y", 6)
  //   .attr("dy", "0.71em")
  //   .style("fill", '#00b71f')
  //   .style("stroke", "none")
  //   .text("Voltage");

  layer.append("text")
    .attr("transform", "translate(90,5)")
    .attr("y", 6)
    .attr("dy", "0.71em")
    .style("fill", '#1193cd')
    .style("stroke", "none")
    .text(`PV (peak ${maxChannel1}w)`);

  layer.append("text")
    // .attr("transform", "rotate(270, 20, 40)")
    .attr("transform", "translate(190,5)")
    .attr("y", 6)
    .attr("dy", "0.71em")
    .style("fill", '#cd7c0a')
    .style("stroke", "none")
    .text(`Meter (peak ${maxTotal}w)`);
};

const drawChart = (selector, dataArray) => {
  const container = d3.select(selector);
  container.select('svg').remove();

  const boundingClientRect = container.node().getBoundingClientRect();
  let height = boundingClientRect.height;
  let width = boundingClientRect.width || boundingClientRect.left || boundingClientRect.right;

  let widthLessMargin = width - margin.left - margin.right;
  let heightLessMargin = height - margin.top - margin.bottom;

  const maxChannel1 = d3.max(dataArray, ({channel1}) => channel1)
  const maxTotal = d3.max(dataArray, ({total}) => total)

  const yScaleLeft = d3.scaleLinear()
    .domain([
      d3.min(dataArray, ({total, channel1}) => Math.min(total, channel1) -20),
      Math.max(maxTotal, maxChannel1) + 250
    ])
    .range([heightLessMargin, 0]);

  const yScaleRight = d3.scaleLinear()
    .domain([220, 240])
    .range([heightLessMargin, 0]);

  const xScale = d3.scaleTime()
    .domain([
      d3.min(dataArray, ({date}) => date),
      d3.timeMinute.offset(d3.max(dataArray, ({date}) => date), 1)])
    .range([0, widthLessMargin]);

  const svg = container.append("svg")
    .attr("width", width)
    .attr("height", height)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  svg.call(drawBackground, widthLessMargin, heightLessMargin);
  svg.call(drawLines, dataArray, widthLessMargin, heightLessMargin, xScale, yScaleLeft, yScaleRight);
  svg.call(drawXAxis, width, heightLessMargin, xScale);
  svg.call(drawYAxis, widthLessMargin, yScaleLeft, yScaleRight, maxChannel1, maxTotal);
};

export const LiveChart = props => {
  const chartEl = useRef(null);

  useEffect(() => {
    drawChart(chartEl.current, props.data);
  }, [props.data])


  return <div ref={chartEl} style={chartStyle}/>
};
