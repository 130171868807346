import React, {useEffect, useState} from "react"
import * as firebase from "firebase/app";
import "firebase/firestore";

import FlexBox from "../components/layout/FlexBox";
import moment from "moment";
import {theme} from "../components/Theme";
import {useAppContext} from "../AppContext";


require("firebase/firestore");
const Style = {
  container: {
    color: "#FFF"
  },
  card: {
    padding: 10,
    margin: 10,
    borderRadius: 10,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  indicator: {
    fontSize: 24,
    fontWeight: 800,
  },
  title: {
    fontSize: 10,
    textAlign: "center",
    marginTop: 5,
  }
}

const useLoadData = () => {
  const {gridId} = useAppContext()
  const [data, setData] = useState(undefined)
  useEffect(() => {
    const db = firebase.firestore()
    const unsub = db.collection(`data/${gridId}/nano-latest`).doc("data").onSnapshot(doc => {
      setData(doc.data())
    });
    return () => { unsub() }
  }, [])
  return data
}

export default () => {
  const data = useLoadData()
  return (
    <FlexBox style={Style.container} column>
      <FlexBox right style={{marginTop: 5, marginRight: 10, fontSize: 14}}>
        {/*<div>Live view last updated {data ? moment(data.dateCreated).fromNow() : "..." }</div>*/}
        <UpdatedSince date={data ? data.date : undefined}/>
      </FlexBox>

      <FlexBox wrap item>
        <Card value={data ? `${data.channel1}w` : "..."} text="PV"/>
        <Card value={data ? `${data.total}w` : "..."} text="Meter"/>
        <Card value={data ? data.voltage : "..."} text="VOLTAGE (volts)"/>
      </FlexBox>
    </FlexBox>
  )
}

const Card = ({value, text}) =>
  <FlexBox style={Style.card} item column centered>
    <div><span style={Style.indicator}>{value}</span></div>
    <div style={Style.title}>{text}</div>
  </FlexBox>

const UpdatedSince = ({date}) => {
  const [label, setLabel] = useState("...")

  useEffect(() => {
    const id = setInterval(() => {
      setLabel(date ? moment(date).format("HH:mm:ss DD MMM YYYY") : "..." )
    }, 1000)
    return () => clearInterval(id)
  }, [date]);

  return <div style={{color: theme.fontColor1}}>Live view last updated {label}</div>
}

