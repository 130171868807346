import {useState, useCallback} from "react";
import axios from "axios";
import {generateHeaders} from "../lib/axiosUtils";

const useServerCall = ({authToken, method = "get", url, trackProgress = false}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(undefined);
  const [percentCompleted, setPercentCompleted] = useState(0);

  let onUploadProgress;
  if (trackProgress) {
    onUploadProgress = progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setPercentCompleted(percentCompleted);
    };
  }

  const clearError = useCallback(() => setError(undefined), [setError]);
  const call = useCallback((data) => {
    setLoading(true);
    return axios({
      method,
      url,
      data,
      headers: authToken ? generateHeaders(authToken) : undefined,
      onUploadProgress
    }).then(res => {
      const idToken = res.headers['x-application-token'];
      if (idToken) {
        const authToken = {tokenType: "Bearer", idToken};
        res.data
          ? setData({data: res.data, authToken})
          : setData({data: {callComplete: true}, authToken})
      } else {
        res.data ? setData(res.data) : setData({callComplete: true})
      }
    }).catch(error => {
      console.error("Error calling server", url, error);
      setError(error);
    }).finally(() => setLoading(false));
  }, [authToken, setLoading, setError, url, method, onUploadProgress]);

  return [data, loading, error, call, clearError, percentCompleted]
};

export const useGet = useServerCall;
export const usePost = ({authToken, url}) => useServerCall({authToken, method: "post", url});
export const useUpload = ({authToken, url}) => useServerCall({authToken, method: "post", url, trackProgress: true});
