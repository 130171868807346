export const theme = {
  success: "#5f9f63",
  warning: "#F8B71D",
  alert: "#C13426",
  borderColor1: "#62666B",
  borderColor2: "#000000",
  fontColor1: "#FCFCFC",
  fontColor2: "#A6E5FF",
  fontColor3: "#F8B71D",
  fontColor4: "#8F969C",
  fontColor5: "#C13426",
  fontColor6: "#434A51",
  linkColor: "#57BFF7",
  backgroundColor1: "#505A63",
  backgroundColor2: "#373F46",
  backgroundColor3: "#2D333A",
  backgroundColor4: "#434A51",
  backgroundColor5: "#3a5b78",
  backgroundColor6: "#6eadd2",
};
