import React from "react"
import {LogOut, LogIn, Menu, Bell} from "react-feather"
import logo from "./logo.jpg"
import {useAppContext} from "../AppContext"
import FlexBox from "../components/layout/FlexBox";
import {theme} from "../components/Theme";
import Loading from "../components/atoms/loading/Loading";
import useOnMount from "../hooks/useOnMount";

const Style = {
  container: {
    backgroundColor: "black",
    padding: 10,
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
  },
  right: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridGap: 10,

  },
  menu: {
    position: "fixed",
    right: 0,
    top: 60,
    minWidth: 200,
    backgroundColor: theme.backgroundColor2,
    borderBottomLeftRadius: 10,
  },
  menuRow: {
    cursor: "pointer",
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,

  },
}

export default () => {
  const {user, setShowMenu, showMenu} = useAppContext();
  return (
    <div style={Style.container}>
      <img src={logo} alt="logo" width={250}/>
      <div style={Style.right}>
        <WhoIsLoggedIn/>
        {user ? <Menu style={{cursor: "pointer"}} size={40} onClick={() => setShowMenu(!showMenu)}/> : <SignInOrOut/>}
      </div>
      {showMenu && <MenuList/>}
    </div>
  )
}

const MenuList = () => {
  const {user, loadingRoles, loadRoles, roles, signIn, signOut, setShowMenu} = useAppContext();
  useOnMount(() => {
    loadRoles(user.authToken)
  })
  return (
    <FlexBox style={Style.menu} column>
      {loadingRoles ? <Loading margin/> :
        <React.Fragment>
          {roles.admin && <NotificationLink/>}
          <FlexBox style={Style.menuRow}
                   onClick={() => {
                     setShowMenu(false);
                     user ? signOut() : signIn()
                   }}>
            <SignInOrOut width={20}/>
            <span style={{paddingLeft: 5}}>{user ? "Sign out" : "Sign in"}</span>
          </FlexBox>
        </React.Fragment>
      }
    </FlexBox>
  )
}

const NotificationLink = () => {
  const {setShowMenu, setShowNotifications} = useAppContext();

  return (
    <FlexBox style={Style.menuRow} vCenter onClick={() => {
      setShowMenu(false);
      setShowNotifications(true)
    }}>
      <Bell size={20}/>
      <span style={{paddingLeft: 5}}>Notifications</span>
    </FlexBox>
  )
}

const WhoIsLoggedIn = ({width = 40}) => {
  const ctx = useAppContext();
  return ctx.user
    ? <img style={{borderRadius: 50}} width={width} src={ctx.user.photoURL} alt="profile"/>
    : null;
}

const SignInOrOut = ({width = 40}) => {
  const {user, signIn, signOut} = useAppContext();
  return user
    ? <LogOut style={{cursor: "pointer"}} color="#FFF" size={width} onClick={signOut}/>
    : <LogIn style={{cursor: "pointer"}} color="#FFF" size={width} onClick={signIn}/>
}
