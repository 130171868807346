import React, {useCallback} from "react"
import moment from "moment"
import {BarChart} from "./BarChart"
import {useAppContext} from "../../AppContext";

const chartStyle = {
  width: 'calc(100vw-0)',
  height: 250
}

export default ({data, mapXAxisToLabel}) => {
  const {size, setSelectedDay} = useAppContext()

  const onItemSelected = useCallback(d => {
    d ? setSelectedDay(moment(d.key).format("YYYY-MM-DD"))
      : setSelectedDay(moment().format("YYYY-MM-DD"))
  }, [setSelectedDay])

  const margin = {top: 20, right: 30, bottom: 50, left: 50}
  const chart = <BarChart {...{
    width: size.width, height: chartStyle.height,
    margin, data, mapXAxisToLabel, onItemSelected
  }}/>
  return <div style={chartStyle}>{chart ? chart : null}</div>
}
