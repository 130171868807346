import React, {useState} from "react"
import * as firebase from "firebase";
import useOnMount from "../hooks/useOnMount";
import Loading from "../components/atoms/loading/Loading";
import FlexBox from "../components/layout/FlexBox";
import {theme} from "../components/Theme";
import Button from "../components/atoms/Button";
import {usePost} from "../hooks/useServerCall";
import {useAppContext} from "../AppContext";

const Style = {
  gridSelectorTitle: {
    fontSize: 24
  },
  gridSelectorItem: {
    minWidth: "100%",
    fontSize: 18,
    backgroundColor: theme.backgroundColor2,
    cursor: "pointer",
    padding: 5,
    marginTop: 5,
    marginBottom: 5,
  },
}

const useLoadNotifications = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(undefined)
  const db = firebase.firestore()
  useOnMount(() => {
    const unsub = db.collection("linkToGridRequest")
      .onSnapshot((docs) => {
        const ans = []
        docs.forEach(doc => ans.push(doc.data()))
        setData(ans)
        setLoading(false)
      })
    return () => { unsub() }
  })
  return [loading, data]
}

const useLoadAvailableGrids = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(undefined)
  const db = firebase.firestore()
  useOnMount(() => {
    const unsub = db.collection("data")
      .onSnapshot((docs) => {
        const ans = []
        docs.forEach(doc => ans.push(doc.data()))
        setData(ans)
        setLoading(false)
      })
    return () => { unsub() }
  })
  return [loading, data]
}

export default () => {
  const [loadingNotifications, notifications] = useLoadNotifications()
  const [loadingGrids, grids] = useLoadAvailableGrids()
  const [notification, setNotification] = useState()
  const [grid, setGrid] = useState()

  let view = <NotificationSelector data={notifications} setNotification={setNotification}/>
  if (notification) view = <GridSelector data={grids} setGrid={setGrid}/>
  if (notification && grid) view = <Selection {...{grid, notification, setGrid, setNotification}}/>

  return loadingNotifications || loadingGrids
    ? <Loading fullScreen message={"Loading notifications"}/>
    : <FlexBox centered fullScreen>{view}</FlexBox>
}

const Selection = ({grid, notification, setGrid, setNotification}) => {
  const {user, functionsApi, setShowNotifications} = useAppContext()
  const [data, loading, error, call] = usePost({url: `${functionsApi}/linkUserToGrid`, authToken: user.authToken})

  if (!grid || !notification) return null
  if (data) return <Success message={data.message} setShowNotifications={setShowNotifications}/>
  if (error) return <FlexBox fullScreen centered>Something has gone wrong, please try again.</FlexBox>
  return loading
    ? <Loading fullScreen message={"Linking user to grid"}/>
    : <FlexBox column vMargin>
      <FlexBox>Would you like to link the user {notification.email} to the grid {grid.name}?</FlexBox>
      <FlexBox vMargin spaceBetween>
        <Button onClick={() => {
          setGrid(undefined)
          setNotification(undefined)
        }}>No</Button>
        <Button onClick={() => {
          call({gridId: grid.name, ...notification})
        }}>Yes</Button>
      </FlexBox>
    </FlexBox>
}

const Success = ({message, setShowNotifications}) => {
  return (
    <FlexBox fullScreen centered column>
      {message}
      <Button
        style={{marginTop: 20}}
        onClick={() => setShowNotifications(false)}>Done</Button>
    </FlexBox>
  )
}

const NotificationSelector = ({data, setNotification}) =>
  data.length
    ? <FlexBox centered column>
      <FlexBox style={Style.gridSelectorTitle} vMargin>Please select a notification</FlexBox>
      <FlexBox centered column style={{width: 300}}>
        {data.map(d => <FlexBox centered
                                style={Style.gridSelectorItem}
                                key={d.email}
                                onClick={() => setNotification(d)}>
            {d.name} {d.email}
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
    : <FlexBox>There are currently no notifications</FlexBox>

const GridSelector = ({data, setGrid}) => {
  return (
    <FlexBox centered column>
      <FlexBox style={Style.gridSelectorTitle} vMargin>Please select a grid</FlexBox>
      <FlexBox centered column style={{width: 300}}>
        {data.map(d => <FlexBox centered
                                style={Style.gridSelectorItem}
                                key={d.name}
                                onClick={() => setGrid(d)}>
            {d.name}
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  )
}
