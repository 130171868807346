import React, {useEffect, useRef} from "react";
import {fromEvent, merge} from "rxjs";
import {debounceTime, filter, map, scan} from "rxjs/operators";
import {ChevronLeft, ChevronRight} from "react-feather";
import {useAppContext} from "../../AppContext";
import FlexBox from "../layout/FlexBox";
import moment from "moment";

const Style = {
  pointer: {
    cursor: "pointer",
  }
}

export default () => {
  const ctx = useAppContext()
  const leftRef = useRef();
  const rightRef = useRef();
  const {setSelectedDay, selectedDay} = ctx;

  useEffect(() => {
    const left$ = fromEvent(leftRef.current, "click").pipe(map(() => -1));
    const right$ = fromEvent(rightRef.current, "click").pipe(map(() => 1));
    const subscription = merge(left$, right$)
      .pipe(
        scan((acc, i) => acc + i, 0),
        map(i => moment(selectedDay).add(i, "day")),
        filter(m => {
          const today = moment()
          return m.isBefore(today, "day") || m.isSame(today, "day")
        }),
        map(m => m.format("YYYY-MM-DD")),
        debounceTime(300),
      )
      .subscribe((day) => {
        setSelectedDay(day)
      });
    return () => {
      subscription.unsubscribe();
    }
  }, [selectedDay, setSelectedDay]);

  return (
    <FlexBox>
      <FlexBox style={Style.pointer} reference={leftRef}><ChevronLeft/></FlexBox>
      <FlexBox style={Style.pointer} reference={rightRef}><ChevronRight/></FlexBox>
    </FlexBox>
  )
}
