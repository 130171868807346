import React, {useRef, useEffect, useState} from "react";
import * as d3 from "d3";

import chartColours from "./colours";

const useDrawBars = (containerRef, data, xScale, yScale, heightLessMargin, onClicked) => {
  const [selectedItem, setSelectedItem] = useState()
  useEffect(() => {
    console.log(selectedItem)
    const selection = d3.select(containerRef.current);
    const x = selection
      .selectAll('rect')
      .data(data, d => {
        return d.key
      });

    x.style('fill', d => {
      d.selected = d.key === (selectedItem && selectedItem.key)
      return d.key === (selectedItem && selectedItem.key) ? "rgb(218,218,218)" : "rgb(170, 170, 170)"
    })

    x.enter()
      .append('rect')
      .on("click", function(d) {
        if (onClicked) {
          if (d.selected) {
            setSelectedItem(undefined)
            onClicked && onClicked(undefined);
          } else {
            setSelectedItem(d)
            d.selected = true;
            onClicked && onClicked(d);
          }
        }
      })
      .style('fill', chartColours.neutral)
      .style('cursor', "pointer")
      .attr('x', d => xScale(d.key))
      .attr('y', heightLessMargin)
      .attr('height', 0)
      .attr('width', xScale.bandwidth())
      .transition()
      .duration(100)
      .delay((d, i) => {
        return i * 15
      })
      .attr('y', function (d) {
        return yScale(d.value);
      })
      .attr('height', function (d) {
        return heightLessMargin - yScale(d.value);
      });

    x.transition()
      .duration(100)
      .delay((d, i) => {
        return i * 15
      })
      .attr('y', function (d) {
        const y = yScale(d.value);
        return y >= 0 ? y : 0;
      })
      .attr('height', function (d) {
        const hy = heightLessMargin - yScale(d.value);
        return hy >= 0 ? hy : 0;
      })

    x.exit().remove();
  }, [selectedItem, data]);
};

export default props => {
  const barsRef = useRef(null);
  const {widthLessMargin, heightLessMargin, data, xScale, yScale, onItemSelected} = props;

  useDrawBars(barsRef, data, xScale, yScale, heightLessMargin, onItemSelected);

  return (
    <g ref={barsRef}
       width={widthLessMargin}
       height={heightLessMargin}/>
  )
}
