import React, {createContext, useEffect, useCallback, useContext, useState} from "react"
import axios from "axios"
import * as firebase from "firebase/app"
import "firebase/auth"

import firebaseConfig from "./FirebaseConfig"
import moment from "moment";
import * as d3 from "d3";

/**
 * Provide a signIn function to sign a user in using firebase
 */

const useFirebaseAuth = () => {
  const [user, setUser] = useState(undefined)

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const idToken = await user.getIdToken()
          console.log(idToken);
          const {uid, displayName, email, photoURL, metadata} = user
          setUser({uid, displayName, email, photoURL, authToken: {tokenType: "Bearer", idToken}, metadata})
        } else {
          setUser(undefined)
        }
      },
      (error) => {
        console.log(error)
      })
    return () => {
      console.log("Un-subscribing from onAuthStateChanged")
      unsubscribe()
    }
  }, [firebase.auth])

  const signIn = useCallback(() => {
    return firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
  }, [setUser, firebase])

  const signOut = useCallback(() => {
    return firebase.auth().signOut()
  }, [firebase])

  return [user, signIn, signOut]
}

// This must only be called when someone is logged in
const useLoadGrids = (apiEndpoint) => {
  const [loading, setLoading] = useState(true);
  const [grids, setGrids] = useState(undefined);
  const loadGrids = useCallback((authToken) => {
    axios.get(
      `${apiEndpoint}/list/grids`,
      {headers: {'Authorization': `${authToken.tokenType} ${authToken.idToken}`}}
    ).then(({data}) => {
      setGrids(data.grids)
      setLoading(false)
    }).catch(error => {
      console.error("Error loading grids", error)
      setLoading(false)
    })
  }, [])
  return [loading, grids, loadGrids]
};

const createBaseData = (startDate, endDate, interval) => {
  console.info(`Creating base data from ${startDate} to ${endDate}`);
  return interval.range(startDate, endDate, 1)
    .map(d => ({key: d, value: 0}))
};

const useLoadData = (selectedDay, gridId) => {
  const [loading, setLoading] = useState(true);
  const [dataGroupByDay, setDataGroupedByDay] = useState(undefined)
  const [dataGroupByMonth, setDataGroupedByMonth] = useState(undefined)

  const loadData = useCallback(() => {
    const selectedMonth = moment(selectedDay)
    const db = firebase.firestore()
    const startOfYear = moment(selectedMonth).startOf("year")
    const endOfYear = moment(selectedMonth).endOf("year")
    const startOfMonth = moment(selectedMonth).startOf("month")
    const endOfMonth = moment(selectedMonth).endOf("month")
    db.collection(`data/${gridId}/nano-day`)
      .where("date", ">=", startOfYear.valueOf())
      .where("date", "<=", endOfYear.valueOf())
      .get()
      .then(querySnapshot => {
        const day = createBaseData(startOfMonth.toDate(), endOfMonth.toDate(), d3.timeDay)
        const month = createBaseData(startOfYear.toDate(), endOfYear.toDate(), d3.timeMonth)

        querySnapshot.docs.forEach(d => {
          const data = d.data()
          const key = moment(data.date)
          const foundDay = day.find(a => moment(a.key).isSame(key, "day"))
          if (foundDay) foundDay.value += (data.total + data.channel1) / 31.5;

          const foundMonth = month.find(a => moment(a.key).isSame(key, "month"))
          if (foundMonth) foundMonth.value += (data.total + data.channel1) / 31.5;

        })
        setDataGroupedByDay(day)
        setDataGroupedByMonth(month)
        setLoading(false)
      })
  }, [selectedDay, gridId])

  return [loading, dataGroupByDay, dataGroupByMonth, loadData]
}

const useIsAdmin = (apiEndpoint) => {
  const [admin, setAdmin] = useState(false)
  const [loading, setLoading] = useState(true)
  const isAdmin = useCallback((authToken) => {
    axios.get(
      `${apiEndpoint}/me`,
      {headers: {'Authorization': `${authToken.tokenType} ${authToken.idToken}`}}
    ).then(({data}) => {
      setAdmin(data)
      setLoading(false)
    }).catch(error => {
      console.error("Error checking user role", error)
      setLoading(false)
    })
  }, [])
  return [loading, admin, isAdmin]
}

const AppContext = createContext(undefined)
export const useAppContext = () => useContext(AppContext)

export default ({children}) => {
  if (!firebase.apps.length) firebase.initializeApp(firebaseConfig)
  const functionsApi = "https://europe-west2-microgrid-90821.cloudfunctions.net/api"
  // const functionsApi = "http://localhost:5001/microgrid-90821/europe-west2/api"
  const [gridId, setGridId] = useState()
  const [showMenu, setShowMenu] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  const [size, setSize] = useState()
  const [user, signIn, signOut] = useFirebaseAuth()
  const [loadingGrids, grids, loadGrids] = useLoadGrids(functionsApi)
  const [selectedDay, setSelectedDay] = useState(moment().format("YYYY-MM-DD"))
  const [loading, dataGroupByDay, dataGroupByMonth, loadData] = useLoadData(selectedDay, gridId)
  const [loadingRoles, roles, loadRoles] = useIsAdmin(functionsApi)

  const sendLinkToGridRequest = (authToken) => {
    axios.post(`${functionsApi}/linkUserToGridRequest`,
      null,
      {headers: {'Authorization': `${authToken.tokenType} ${authToken.idToken}`}})
      .then(({data}) => console.log(data))
      .catch(error => console.error(error))
  }


  return (
    <AppContext.Provider value={{
      ...{
        loadingGrids, grids, loadGrids, setGridId, user, gridId, selectedDay, setSelectedDay, sendLinkToGridRequest,
        signIn, signOut, size, setSize, loading, dataGroupByDay, dataGroupByMonth, loadData, showMenu, setShowMenu,
        showNotifications, setShowNotifications, loadingRoles, roles, loadRoles, functionsApi
      }
    }}>
      {children}
    </AppContext.Provider>
  )
}
