import React from 'react'
import Spinner from "./HSpinner"
import FlexBox from "../../layout/FlexBox";
import {theme} from "../../Theme";

const Style = {
  message: {
    color: theme.fontColor4
  }
}

export default ({fullScreen, message = "Loading...", margin}) => {
  return (
    <FlexBox column centered {...{margin, fullScreen}}>
      <Spinner/>
      <FlexBox>
        <span style={Style.message}>{message}</span>
      </FlexBox>
    </FlexBox>
  )
}
