import React from "react";
import Radium from "radium";
import {theme} from "../Theme";

const buttonStyle = {
  button: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    gridTemplateColumns: "auto auto",
    color: theme.linkColor,
    fontWeight: 600,
    backgroundColor: theme.backgroundColor5,
    minWidth: 200,
    minHeight: 40,
    borderRadius: 15,
    cursor: "pointer",
    userSelect: "none",
  },
  text: {
    textAlign: "center",
  },
  disabled: {
    backgroundColor: theme.backgroundColor1,
    color: theme.fontColor4,
    cursor: "not-allowed"
  },
};

const defaultOnClickHandler = () => console.log("clicked");

export default Radium(({
                         text = "Click me",
                         onClick = defaultOnClickHandler,
                         disabled = false,
                         style = undefined,
                         children
                       }) =>
  <div style={[buttonStyle.button, disabled && buttonStyle.disabled, style]}
       onClick={!disabled ? onClick : null}>
    <div style={buttonStyle.text}>{children || text}</div>
  </div>
)
