import React, {useRef, useEffect} from "react";
import * as d3 from "d3";

const useDrawYAxis = (layer, yScale) => {
  useEffect(() => {
    const axis = d3.select(layer.current)
      .call(d3.axisLeft(yScale).ticks(5));

    axis.selectAll("text")
      .style("fill", '#c1c1c1')
      .style("stroke", 'none');

    axis.selectAll("path")
      .style("stroke", '#c1c1c1');

    axis.selectAll("line")
      .style("stroke", '#c1c1c1');
  });
};

export default props => {
  const layer1 = useRef(null);
  const {yScale} = props;
  useDrawYAxis(layer1, yScale);

  return (
    <React.Fragment>
      <g ref={layer1} className="y-axis"/>
    </React.Fragment>
  )
}