import React, {useRef, useEffect} from "react";
import * as d3 from "d3";
import "./XAxis.css";

const useDrawXAxis = (layer, xScale, mapXAxisToLabel) => {
  useEffect(() => {
    const hourOfDay = d3.axisBottom(xScale)
      .tickFormat(mapXAxisToLabel ? mapXAxisToLabel : (d) => d);

    d3.select(layer.current)
       .call(hourOfDay)
      .selectAll("text")
      .style("text-anchor", 'center')
      .style("fill", '#c1c1c1')
      .style("stroke", 'none')
  });
};

export default props => {
  const layer1 = useRef(null);
  const {heightLessMargin, xScale, mapXAxisToLabel} = props;
  useDrawXAxis(layer1, xScale, mapXAxisToLabel);

  return (
    <React.Fragment>
      <g ref={layer1} className="x-axis" transform={`translate(0, ${heightLessMargin})`}/>
    </React.Fragment>
  )
}