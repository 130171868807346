import {useState, useEffect} from "react";

export const useMeasure = (ref) => {
  const [size, setSize] = useState({width: undefined, height: undefined, x: undefined, y: undefined});
  useEffect(() => {
    const {width, height, x, y} = ref.current.getBoundingClientRect();
    setSize({width, height, x, y});
  }, [ref]);
  return size;
};
